<template>
  <form-view
    url="/company/scheduleLevelTwo/assign"
    type="assign"
    :data-form="dataForm"
    v-loading="loading"
  >
    <el-row :gutter="25">
      <el-col :span="6">
        <el-form-item label="公司名称" prop="companyId">
          <el-select disabled v-model="dataForm.companyId">
            <el-option v-for="s in companyList" :key="s.id" :label="s.companyName" :value="s.id"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="25" v-if="dataForm.dispatcherUserId">
      <el-col :span="6">
        <el-form-item label-width="82px" label="原二级调度" prop="dispatcherUserId">
          <el-select disabled v-model="dataForm.dispatcherUserId">
            <el-option v-for="s in userList" :key="s.id" :label="s.nickName" :value="s.id"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-table
      v-if="dataForm.dispatcherUserId"
      :data="originUserTrainList"
      stripe
      size="mini"
      :border="true"
      :max-height="300"
    >
      <el-table-column
        header-align="center"
        align="center"
        v-for="h in headers"
        :key="h.id"
        :label="h.label"
        :prop="h.prop"
        :width="h.width"
        :min-width="h.minWidth"
        show-overflow-tooltip
      ></el-table-column>
    </el-table>
    <el-row :gutter="25">
      <el-col :span="6">
        <el-form-item
          label-width="92px"
          label="新二级调度"
          prop="userId"
          :rules="{required:true,message:'请选择二级调度'}"
        >
          <el-select filterable v-model="dataForm.userId" @change="selectUser">
            <el-option
              v-for="s in userList"
              :key="s.id"
              :label="s.nickName"
              :value="s.id"
              :disabled="s.id === dataForm.dispatcherUserId"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-table
      v-if="dataForm.userId"
      :data="newUserTrainList"
      stripe
      size="mini"
      :border="true"
      :max-height="300"
    >
      <el-table-column
        header-align="center"
        align="center"
        v-for="h in headers"
        :key="h.id"
        :label="h.label"
        :prop="h.prop"
        :width="h.width"
        :min-width="h.minWidth"
        show-overflow-tooltip
      ></el-table-column>
    </el-table>
    <div class="section-title-row" v-if="id">
      <div class="title">操作历史</div>
      <div class="line"></div>
    </div>
    <div class="record-area" v-if="id">
      <div class="record-row" v-for="(r, i) in records" :key="i">{{r}}</div>
    </div>
  </form-view>
</template>

<script>
import FormView from '@/components/templates/form-view'

export default {
  name: 'car-partner-assign',

  components: { FormView },

  data () {
    return {
      loading: false,
      dataForm: {
        userId: ''
      },
      userList: [],
      companyList: [],
      originUserTrainList: [],
      newUserTrainList: [],
      headers: [
        { id: 1, label: '培训名称', prop: 'attrName', width: 200 },
        { id: 2, label: '培训日期', prop: 'attrDate', width: 100 },
        { id: 3, label: '有效期截至日期', prop: 'closingDate', width: 150 },
        { id: 4, label: '备注', prop: 'remarks', minWidth: 120 }
      ],
      records: [],
      id: null
    }
  },

  created () {
    this.init()
  },

  methods: {
    async init () {
      const id = this.id = this.$route.query.id
      this.loading = true
      const userData = await this.$http({
        url: this.$http.adornUrl('/user/list'),
        method: 'post',
        data: {}
      })
      this.userList = userData.datas
      const companyData = await this.$http({
        url: this.$http.adornUrl('/company/list'),
        method: 'post',
        data: {}
      })
      this.companyList = companyData.datas
      if (id) {
        const data = await this.$http({
          url: this.$http.adornUrl('/company/getById'),
          method: 'post',
          data: id
        })
        if (data && data.code === 200) {
          const carModel = data.datas
          this.dataForm = {
            companyId: carModel.id,
            dispatcherUserId: carModel.dispatcherUserId,
            userId: ''
          }
          if (carModel.dispatcherUserId) {
            const originTableData = await this.$http({
              url: this.$http.adornUrl('/user/train/listForPage'),
              method: 'post',
              data: {
                pageNum: 1,
                pageSize: 999,
                userId: carModel.dispatcherUserId
              }
            })
            this.originUserTrainList = originTableData.datas.list
          }
          const res = await this.$http({
            url: this.$http.adornUrl('/company/scheduleLevelTwo/operationRecord'),
            method: 'post',
            data: id
          })
          this.records = res.datas.map(d => {
            if (d.dispatcherStatus === 3) {
              return `${d.createTime} ${d.nickName}进行了审批驳回操作，备注内容：${d.reviewDispatcherRemark || ''}。`
            } else if (d.dispatcherStatus === 1) {
              return `${d.createTime} ${d.nickName}审批通过。`
            }
            return `${d.createTime} ${d.nickName}修改二级调度为${d.reviewDispatcherUserName}，修改前为${d.dispatcherUserName || '无'}。`
          })
          this.loading = false
        }
      }
    },

    async selectUser (userId) {
      const data = await this.$http({
        url: this.$http.adornUrl('/user/train/listForPage'),
        method: 'post',
        data: {
          pageNum: 1,
          pageSize: 999,
          userId
        }
      })
      this.newUserTrainList = data.datas.list
    }
  }
}
</script>

<style lang="scss" scoped>
.el-table {
  margin-bottom: 30px;
}
</style>
